/**
 * Created by osirvent on 21/03/2017.
 */
angular
    .module('annexaApp')
    .factory('TableFilterFactory', ['$q', 'RestService', 'Language', function ($q, RestService, Language) {
        var factory = {};

        factory.loadData = function (filter) {
            var promises = [];

            _.forEach(filter, function (value) {
                if(_.contains(['select', 'select_linked', 'select-multiple', 'select-tree'], value.type)) {
                    var deferred = $q.defer();

                    switch (value.dataType) {
                        case 'DATABASE':
                            if (typeof value.data === 'string') {
                                RestService.findAll(value.data)
                                    .then(function (data) {
                                        deferred.resolve({id: value.id, data: JSOG.decode(data.data)});
                                    }).catch(function (error) {
                                    deferred.reject(error);
                                });
                            } else {
                                deferred.resolve({id: value.id, data: value.data});
                            }
                            break;
                        case 'QUERY':
                            if (typeof value.data === 'string') {
                                RestService.query(value.data, value.dataQuery, value.dataQueryParams)
                                    .then(function (data) {
                                        deferred.resolve({id: value.id, data: JSOG.decode(data.data)});
                                    }).catch(function (error) {
                                    deferred.reject(error);
                                });
                            } else {
                                deferred.resolve({id: value.id, data: value.data});
                            }
                            break;
                        case 'LOCAL':
                            deferred.resolve({ id: value.id, data: value.data });
                            break;
                        default:
                            deferred.reject('DataType not valid');
                            break;
                    }

                    promises.push(deferred.promise);
                }
            });

            return $q.all(promises);
        }

        var getModel = function(item, selectId) {
            switch (item.type) {
                case 'checkbox':
                    return item.model != undefined ? item.model : (item.preFilter ? item.preFilter : false);
                case 'text':
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'select-multiple':
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'select':
                case 'select_linked':
                    if(!selectId) {
                        return item.model && item.model.id ? {id: item.model.id} : (item.preFilter ? {id: item.preFilter} : undefined);
                    } else {
                        return item.model && item.model[selectId] ? {id: item.model[selectId]} : (item.preFilter ? {id: item.preFilter} : undefined);
                    }
                case 'select-tree':
                    return item.model && item.model.$selected && item.model.$selected.id ? { id: item.model.$selected.id } : (item.preFilter ? {id: item.preFilter} : undefined);
                case 'select-multiple':
                    return item.model ? item.model : (item.preFilter ? item.preFilter : undefined);
                case 'dateRange':
                    var value = { From: undefined, To: undefined };

                    if(item.model) {
                        if(item.model.dataFrom) {
                            var dateAux = item.model.dataFrom;
                            value.From = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),00,00,00));
                        }

                        if(item.model.dataTo) {
                            var dateAux = item.model.dataTo;
                            value.To = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),23,59,59));
                        }
                    }

                    return value;
            }

            return undefined;
        }

        factory.getFilterCall = function (filter) {
            var filterCall = {};

            var filterCallColumns = $linq(filter).where("x => !x.callAux && !x.callMeta").toArray();

            _.forEach(filterCallColumns, function (value) {
                if(value.type != 'dateRange') {
                    filterCall[value.id] = getModel(value);
                    if(value.type == 'text'){
                        value.preFilter = undefined;
                    }
                } else {
                    var model = getModel(value);
                    filterCall[value.id + 'From'] = model.From;
                    filterCall[value.id + 'To'] = model.To;
                    value.preFilter = undefined;
                }
            });

            return filterCall;
        }

        factory.getFilterCallAux = function (filter, skipCustomfields) {
            var filterCallAux = {};

            var filterCallAuxColumns = $linq(filter).where("x => x.callAux").toArray();

            _.forEach(filterCallAuxColumns, function (value) {
                if(value.type != 'dateRange') {
                    filterCallAux[value.id] = getModel(value);
                    if(value.type == 'text'){
                        value.preFilter = undefined;
                    }
                } else {
                    var model = getModel(value);
                    filterCallAux[value.id + 'From'] = model.From;
                    filterCallAux[value.id + 'To'] = model.To;
                    value.preFilter = undefined;
                }
            });

            if(!skipCustomfields) {
                filterCallAux.customFields = factory.getFilterMeta(filter);
            }

            return filterCallAux;
        }

        factory.getFilterMeta = function (filter) {
            var filterMeta = {};

            var filterMetaColumns = $linq(filter).where("x => x.callMeta").toArray();

            _.forEach(filterMetaColumns, function (value) {
                filterMeta[value.id] = getModel(value, 'value');
                if(value.type == 'text'){
                    value.preFilter = undefined;
                }
            });

            return filterMeta;
        }

        factory.getCustomFieldFilter = function(customField, order) {
            var filterField = {
                id: customField.templateTag,
                order: order,
                label: customField[Language.getActiveColumn()],
                callMeta: true
            };

            if (_.contains(['INPUT', 'TEXTAREA'], customField.frontendType)) {
                if (customField.backendType == 'DATETIME') {
                    filterField.type = 'dateRange';
                } else {
                    filterField.type = 'text';
                }
            } else if (_.contains(['SELECT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO'], customField.frontendType)) {
                filterField.type = 'select';
                filterField.dataType = 'LOCAL';
                filterField.data = customField.listValues;
                filterField.addAll = true;
                filterField.nameProperty = Language.getActiveColumn();
                filterField.customFieldId = customField.id;
            } else if (customField.frontendType == 'SELECT_LINKED') {
                filterField.type = 'select_linked';
                filterField.dataType = 'LOCAL';
                filterField.data = [];
                filterField.addAll = true;
                filterField.nameProperty = Language.getActiveColumn();
                filterField.linkedData = customField.listValues;
                filterField.linkedCustomFieldId = customField.linkedCustomField.id;
            } else {
                filterField.type = 'UNKNOWN'
            }

            return filterField;
        }

        factory.clearFilterModel = function(field) {
            switch(field.type) {
                case 'select-multiple':
                    return [];
                case 'select':
                case 'select_linked':
                    if(field.data && field.data.length > 0) {
                        return field.data[0];
                    } else {
                        return '';
                    }
                case 'checkbox':
                    return false;
                case 'text':
                    return '';
                case 'dateRange':
                    return undefined;
                case 'select-tree':
                    return {};
            }
        }

        factory.clearFilters = function (filter) {
            _.forEach(filter, function(value, index) {
                switch(value.type) {
                    case 'select-multiple':
                        filter[index].model = [];
                        break;
                    case 'select':
                    case 'select_linked':
                        if(filter[index].data && filter[index].data.length > 0) {
                            filter[index].model = filter[index].data[0];
                        } else {
                            filter[index].model = '';
                        }
                        break;
                    case 'checkbox':
                        filter[index].model = false;
                        break;
                    case 'text':
                        filter[index].model = '';
                        break;
                    case 'dateRange':
                        filter[index].model = undefined;
                        break;
                    case 'select-tree':
                        filter[index].model = {};
                        break;
                }
            });
        }

        return factory;
    }])
    .component('tableFilter', {
        templateUrl: function($element, $attrs) {
            return $attrs.advancedSearch == 'true' ?
                './components/common/table-filter/advanced-table-filter.html' :
                './components/common/table-filter/table-filter.html'
        },
        controller: ['TableFilterFactory', 'HelperService', 'CommonService', 'Language', '$filter', '$rootScope', '$scope', function (TableFilterFactory, HelperService, CommonService, Language, $filter, $rootScope, $scope) {
            var vm = this;

            //region Inici

            //region Advanced Search

            vm.userChoosenFilters = [];
            vm.selectedUserChoosenFilter = undefined;

            //endregion

            vm.collapsed = vm.see != undefined && (vm.see == true || vm.see == 'visible' || vm.see == 'true') ? false : true;

            vm.dataLoaded = false;

            _.forEach(vm.filter, function (value, index) {
                switch (value.type) {
                    case 'select':
                    case 'select_linked':
                        break;
                    case 'select-tree':
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : {};
                        break;
                    case 'select-multiple':
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : [];
                        break;
                    case 'checkbox':
                        vm.filter[index].model = vm.filter[index].model != undefined ? vm.filter[index].model : (vm.filter[index].preFilter ? vm.filter[index].preFilter : false);
                    default:
                        vm.filter[index].model = vm.filter[index].model ? vm.filter[index].model : '';
                        break;
                }

                if(value.type == 'dataRange') {
                    vm.filter[index].openFrom = false;
                    vm.filter[index].openTo = false;
                }
            });

            TableFilterFactory.loadData(vm.filter)
                .then(function(data) {
                    vm.dataLoaded = true;

                    _.forEach(data, function(value) {
                        var indexFilter = $linq(vm.filter).indexOf("x => x.id == '" + value.id + "'");
                        if(indexFilter != -1) {
                            var data = [];

                            if(vm.filter[indexFilter].type != 'select-tree') {
                                if (vm.filter[indexFilter].addAll) {
                                	var literalAll = $filter('translate')('global.literals.all');
                                    var haveAll = $linq(value.data).where("x =>x."+vm.filter[indexFilter].nameProperty+" == '"+ literalAll +"'").toArray();
                                    if(haveAll && haveAll.length > 0){
                                        data = value.data;
                                    }else {
                                        data = HelperService.addAllSelect(value.data, vm.filter[indexFilter].nameProperty);
                                    }
                                } else {
                                    data = value.data;
                                }
                                if (vm.filter[indexFilter].addItems && vm.filter[indexFilter].addItems.length > 0){
                                    _.forEach(vm.filter[indexFilter].addItems, function(itemToAdd){
                                        if(itemToAdd && itemToAdd.id) {
                                            var existData = $linq(data).where("x => x.id ==" + itemToAdd.id).toArray();
                                            if (!existData || existData.length == 0) {
                                                data.push(itemToAdd);
                                            }
                                        }
                                    });
                                }
                            } else {
                                if(value.data && value.data[0] && !value.data[0].id){
                                    data = value.data;
                                }else{
                                    data = CommonService.getTreeData(value.data, Language.getActiveColumn());
                                }
                            }

                            vm.filter[indexFilter].data = data;

                            if((vm.filter[indexFilter].type == 'select' || vm.filter[indexFilter].type == 'select_linked') && data.length != 0) {
                                var indexSelected = 0;

                                if(vm.filter[indexFilter].preFilter) {
                                    var indexPrefilter = $linq(vm.filter[indexFilter].data).indexOf("x => x.id == '" + vm.filter[indexFilter].preFilter + "'");
                                    vm.filter[indexFilter].preFilter = undefined;

                                    indexSelected = indexPrefilter != -1 ? indexPrefilter : indexSelected;
                                }

                                if(!vm.filter[indexFilter].model) {
                                    vm.filter[indexFilter].model = vm.filter[indexFilter].data[indexSelected];
                                }
                            }else  if(vm.filter[indexFilter].type == 'select-multiple' && data.length != 0){
                                if(vm.filter[indexFilter].preFilter && vm.filter[indexFilter].preFilter.length > 0) {
                                    if(!vm.filter[indexFilter].model) {
                                        vm.filter[indexFilter].model = [];
                                    }
                                    _.forEach(vm.filter[indexFilter].preFilter, function(prefilterItem){
                                        var indexPrefilter = $linq(vm.filter[indexFilter].data).indexOf("x => x.id == '" + prefilterItem.id + "'");
                                        indexSelected = indexPrefilter != -1 ? indexPrefilter : indexSelected;
                                        vm.filter[indexFilter].model.push(vm.filter[indexFilter].data[indexSelected]);
                                    });
                                    vm.filter[indexFilter].preFilter = undefined;
                                }
                            }
                        }
                    });

                    var b = 0;
                }).catch(function(error) {
                    vm.dataLoaded = true;
            });

           //endregion

            //region Mètodes

            //region Advanced Search

            vm.getAdvancedFilterNormal = function() {
                return $linq(vm.filter).where("x => !x.userChoosen").orderBy("x => x.order").toArray();
            }

            vm.getAdvancedFilterUserChoosen = function() {
                return $linq($linq(vm.filter).where("x => x.userChoosen").orderBy("x => x.order").toArray()).except(vm.userChoosenFilters, "(x, y) => x.id == y.id").toArray();
            }

            vm.userChoosenFilterSelected = function($item, $model) {
                vm.userChoosenFilters.push($item);
            }

            vm.addUserChoosenFilter = function() {
                vm.selectedUserChoosenFilter = undefined;
            }

            vm.removeUserChoosenFilter = function(index) {
                var indexFilter = $linq(vm.filter).indexOf("x => x.id == '" + vm.userChoosenFilters[index].id + "'");

                if(indexFilter != -1) {
                    vm.filter[indexFilter].model = TableFilterFactory.clearFilterModel(vm.filter[indexFilter])
                }

                vm.userChoosenFilters.splice(index, 1);
                if(vm.userChoosenFilters.length == 0) {
                    vm.selectedUserChoosenFilter = undefined;
                } else {
                    vm.selectedUserChoosenFilter = vm.userChoosenFilters[vm.userChoosenFilters.length - 1];
                }

                vm.submit();
            }

            vm.clearTree = function(field) {
                field.model = {};
            }

            //endregion

            vm.showLegend = function() {
                var result = false;

                _.forEach(vm.filter, function (value) {
                    if(value.model) {
                        switch (value.type) {
                            case 'select-multiple':
                                result = result || value.model.length != 0;
                                break
                            case 'select':
                            case 'select_linked':
                                result = result || value.model.id;
                                break;
                            case 'select-tree':
                                if(value.model.hasOwnProperty('$selected')) {
                                    result = result || value.model.$selected.title;
                                }
                                break;
                            case 'text':
                                result = result || value.model;
                                break;
                            case 'checkbox':
                                if(value.model != undefined) {
                                    result = result || value.model;
                                }
                                break;
                            case 'dateRange':
                                result = result || value.model.dataFrom || value.model.dataTo;
                                break;
                        }
                    }
                });

                return result;
            }

            vm.changeValue = function(field, deleted, index) {
                if(field && field.type && deleted){
                    if(field.type == 'select-multiple'){
                        field.model.splice(index, 1);
                    }else if(field.type == 'select'){
                        field.model=field.data[0];
                    }else if(field.type == 'select-tree'){
                        field.model={};
                    }else if(field.type == 'checkbox'){
                        field.model='';
                    }else if(field.type == 'text'){
                        field.model='';
                    }else if(field.type == 'dateRange'){
                        field.model=undefined;
                    }
                }
                if(field.type != 'text') {
                    if(field.type == 'select') {
                        if(field.customFieldId) {
                            var linkedFields = $linq(vm.filter).where("x => x.linkedCustomFieldId == " + field.customFieldId).toArray();

                            _.forEach(linkedFields, function (value) {
                                var firstElement = value.data[0];
                                value.model = firstElement;
                                value.data = [];
                                value.data.push(firstElement);

                                if(field.model.id) {
                                    value.data.push.apply(value.data, $linq(value.linkedData).where("x => x.parentValue.id == " + field.model.id).toArray());
                                }
                            });
                        }
                    }
                    if(deleted){
                        $rootScope.$broadcast('tableDataRemoveFilterComplete');
                    }else{
                        vm.submit();
                    }
                } else if(field.type == 'text' && (field.model == '' || field.model.length >= 3)) {
                    if(deleted){
                        $rootScope.$broadcast('tableDataRemoveFilterComplete');
                    }else{
                        vm.submit();
                    }
                }
            }

            vm.clearFilters = function() {
                TableFilterFactory.clearFilters(vm.filter);

                vm.submit();
            }

            //endregion

            $scope.$on('annexaUISelectTreeCleared', function (event, args) {
                if(args.idModel) {
                    var index = $linq(vm.filter).indexOf("x => x.id == '" + args.idModel + "'");

                    if(index != -1) {
                        vm.filter[index].model = {};

                        vm.changeValue(vm.filter[index]);
                    }
                }
            })
        }],
        bindings: {
            filter: '=',
            submit: '&',
            see: '=?',
            advancedSearch: '='
        }
    })