angular
    .module('annexaApp')
    .component('annexaBoxAddress', {
        templateUrl: './components/common/annexa-box-address/annexa-box-address.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'globalModals', 'ModalService', '$rootScope', 'GlobalDataFactory','Language', 'TerritoryFactory', '$filter', function (AnnexaObjectBoxFactory, AnnexaEntityFactory, globalModals, ModalService, $rootScope, GlobalDataFactory, Language, TerritoryFactory,$filter) {
            var vm = this;
            
            this.$onInit = function() {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'view_territory', 'view_territory', vm.isEdit);
                vm.languageColumn = Language.getActiveColumn();
                vm.territoryModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.territory'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.territory.list.toptitle'; 
                }

                if(vm.search) {
                	vm.search.addedSelect = 'x => x.address.id';
                    vm.search.origin = 'territory';
                    vm.search.type = 'Address';
                    vm.search.customFilter = '{ "addresslanguage1": "@VAL@", "addresslanguage2": "@VAL@", "addresslanguage3": "@VAL@"}';
                    vm.search.processSearch = function (object) {
                    	return object["address"+vm.languageColumn];
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                            var newContent = [];
                            if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push({address: JSOG.decode(obj)});
                        		});
                        	}else{
                    			newContent.push({address: JSOG.decode(vm.search.searchedValue.object)} );
                        	}
                            vm.search.searchedValue = { id: -1, value: '' };
                    		if(!vm.search.saveSearch) {
                    			_.forEach(newContent, function(obj){
                    				vm.content.push(obj);
                            	});
                            }else{
                                if(vm.search.saveSearch) {
                                    vm.search.saveSearch(newContent);
                                }
                            }
                        }
                    }
                }

                if(vm.new) {
                	vm.new.newFunc = function() {
                		var getLangugage  = function(address){
                        	return address["address"+vm.languageColumn];
                		}
                		var preCloseModal = function(address) {
                            vm.search.searchedValue = {
                                id: address.id, value: address["address"+vm.languageColumn],
                                object: {
                                	id: address.id,
                            		addresslanguage1:getLangugage(address),
                            		addresslanguage2:getLangugage(address),
                            		addresslanguage3:getLangugage(address)
                                }
                            };
                            vm.search.completeAdd();
                        }
                		
                		var newModal =  TerritoryFactory.newAddress(undefined, preCloseModal, true);
                    };
                    
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxAddressRender', {
        templateUrl: './components/common/annexa-box-address/annexa-box-address-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'HelperService','Language', function(DialogsFactory, $rootScope, HelperService,Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteAddressBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxAddressDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })