angular
    .module('annexaApp')
    .component('tableEditable', {
        templateUrl: './components/common/table-editable/table-editable.html',
        controller:['$filter', 'CommonService', '$rootScope', function($filter, CommonService, $rootScope) {
            var vm = this;
            vm.changeOption = function(column, $data, itemIdAxu){
            	if(column.changeFunction){
            		column.changeFunction($data, itemIdAxu);
            	}
            }
            
            vm.checkRequired = function($data, column, item) {
                if(column.required && !$data) {
                	return $filter('translate')('global.validation.required');
                }else if(column.checkRequiredFunction){
                	return column.checkRequiredFunction($data, item);
                }
            }

            vm.printUser = function(user) {
                if(user.value && user.user) {
                    return user.value;
                } else {
                    return 'global.documents.modal.sendToSign.empty';
                }
            }

            vm.loadUsers = CommonService.loadUsers;

            vm.inserted = {};

            vm.addItem = function() {
                vm.inserted = vm.newItem();

                vm.items.push(vm.inserted);
            }

            vm.columnsWithoutActions = function () {
                return $linq(vm.columns).where("x => x.type != 'actions'").toArray();
            }
            
            this.$onInit = function() {
            	if(!vm.addLabel){
            		vm.addLabel = $filter('translate')('global.documents.modal.sendToSign.addUser');
            	}else{
            		vm.addLabel = $filter('translate')(vm.addLabel);
            	}
            	if(!vm.emptyLabel){
            		vm.emptyLabel = $filter('translate')('global.documents.modal.sendToSign.empty');
            	}else{
            		vm.emptyLabel = $filter('translate')(vm.emptyLabel);
            	}
            	if(vm.columns && vm.columns.length > 0){
            		_.forEach(vm.columns, function(item){
            			if(item.type == "select" && !item.idProperty){
            				item.idProperty = 'id';
            			}
            		})
            	}
            }
        }],
        bindings: {
            columns: '=',
            items: '=',
            orderBy: '@',
            update: '=',
            newItem: '=',
            removeItem: '=',
            addLabel: '=?',
            emptyLabel: '=?'
        }
    })